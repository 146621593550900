import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Link, Typography, Icon } from "@material-ui/core";
import MainMenu from "./components/MainMenu";
import AccountSyncMessage from "./components/services/AccountSyncMessage";

// var config = require('config');

import "./styles/components/responsive-design.scss";
import { connect } from "react-redux";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      syncing: false,
      auto: true,
      noNumber: false,
      forceCancelling: true,
    };
  }

  //const [syncing, setSyncing] = React.useState(false);

  hideSync = () => {
    this.setState({ syncing: false });
  };

  showManualSync = () => {
    //this.setState({ syncing: true, auto: false });
  };

  showAutoSync = () => {
    this.setState({ syncing: true, auto: true });
  };

  switchNoNumber = () => {
    this.setState({ noNumber: true });
  };

  turnOffNoNumber = () => {
    this.setState({ noNumber: false });
  };

  render() {
    const {
      component: Component,
      phoneNumber,
      currentUserInfo,
      globalLoading,
      ...rest
    } = this.props;
    const { syncing, auto, forceCancelling } = this.state;

    var cancelling = false;
    var cancelEnd = "";
    var cancelPlan = "";
    var cancelled = false;
    var deniedPage = this.props.deniedPage;
    var refreshInvalid = false;

    var loggedIn = false;
    if (localStorage.getItem("user")) {
      var user = JSON.parse(localStorage.getItem("user"));
      loggedIn = user.success.token ? true : false;
      if (
        user.success &&
        user.success.user &&
        user.success.user.churches &&
        user.success.user.churches.length > 0
      ) {
        cancelling =
          forceCancelling && user.success.user.churches[0].is_cancelling
            ? user.success.user.churches[0].cancel_date
            : false;
        cancelled = user.success.user.churches[0].is_cancelled
          ? user.success.user.churches[0].cancel_date
          : false;
        cancelPlan =
          user.success.user.churches[0].intercom_tag &&
          user.success.user.churches[0].intercom_tag.startsWith("Trial")
            ? "Trial"
            : "Plan";
        cancelEnd = user.success.user.churches[0].cancel_end_date;
        refreshInvalid =
          user.success.user.churches[0].pivot.refresh_invalid > 0;
      } else {
        console.log(user);
      }
    }

    return (
      <>
        <AccountSyncMessage
          isSyncing={syncing}
          hideSync={this.hideSync}
          auto={auto}
        />
        {!phoneNumber?.number && !globalLoading && (
          <div className="account-syncing-message warning">
            {currentUserInfo?.status > 3 ? (
              <Typography className="message">
                Your phone number was released after your account was suspended.
                To continue, please add a new number by clicking{" "}
                <Link href="/number">here</Link>
              </Typography>
            ) : (
              <Typography className="message">
                You didn't pick your phone number yet. To continue, please add a
                number by clicking <Link href="/number">here</Link>.
              </Typography>
            )}
          </div>
        )}
        {cancelling && cancelPlan === "Trial" && (
          <div className="account-syncing-message warning">
            <Typography className="message">
              On {cancelling} you requested to close your account. This will
              occur on {cancelEnd}
            </Typography>
            <Icon
              className="close-icon"
              onClick={(forceCancelling) =>
                this.setState({ forceCancelling: false })
              }
            >
              close
            </Icon>
          </div>
        )}
        {cancelling && cancelPlan !== "Trial" && (
          <div className="account-syncing-message warning">
            <Typography className="message">
              Your subscription will be canceled on {cancelEnd}. Your account
              will remain open until that date.{" "}
              <Link href="/billing">Resubscribe?</Link>
            </Typography>
            <Icon
              className="close-icon"
              onClick={(forceCancelling) =>
                this.setState({ forceCancelling: false })
              }
            >
              close
            </Icon>
          </div>
        )}
        {cancelled && (
          <div className="account-syncing-message warning">
            <Typography className="message">
              You cancelled your {cancelPlan} on {cancelled} and your account is
              now closed. To continue, you need to click on{" "}
              <Link href="/billing">SUBSCRIBE</Link>.
            </Typography>
          </div>
        )}
        {refreshInvalid && (
          <div className="account-syncing-message warning">
            <Typography className="message">
              We've detected an issue with your sync configuration
              <Link href="/integrations">Re-authentication</Link> required.
            </Typography>
          </div>
        )}
        <div
          className={syncing ? "syncing" : ""}
          style={{
            display: "flex",
            height: "100%",
            position: "relative",
            zIndex: 2,
          }}
        >
          <MainMenu
            showSync={this.showAutoSync}
            switchNoNumber={this.switchNoNumber}
            turnOffNoNumber={this.turnOffNoNumber}
          />
          <Route
            {...rest}
            render={(props) =>
              loggedIn ? (
                cancelled && deniedPage ? (
                  <Redirect
                    to={{
                      pathname: "/billing",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <Component {...props} showSync={this.showManualSync} />
                )
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    phoneNumber: store.userProfileReducer.phone_number,
    currentUserInfo: store.userProfileReducer.user,
    globalLoading: store.global.globalLoading,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
