import React from "react";
import { Icon, Typography } from "@material-ui/core";
import "./../../styles/components/responsive-design.scss";

export default function AccountSyncMessage(props) {
  return (
    <React.Fragment>
      {props.isSyncing && props.auto && (
        <div className="account-syncing-message">
          <Typography className="message">
            Please wait , Your account is syncing now!
          </Typography>
          <Icon className="close-icon" onClick={props.hideSync}>
            close
          </Icon>
        </div>
      )}
    </React.Fragment>
  );
}
